import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Drawer, Input, Select, DatePicker, Tag, Tooltip, Switch, Checkbox } from 'antd';
import { CloseOutlined, CaretDownOutlined, ExclamationCircleOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { TOPIC_ATTRIBUTE_TYPE, SEND_FREQUENCY_OPTIONS, EDM_TEMPLATE_OPTIONS, NUMBER_TC } from 'src/constants/normal';
import i18n from 'src/i18n';

import CubeIcon from 'src/assets/common/cube.svg';
import ArrowIcon from 'src/assets/common/arrow.svg';

import PlusTag from './components/plusTag';
import AttributeTag from './components/AttributeTag';
import FilledButton from '../CustomizeButton/FilledButton';
import NormalButton from '../CustomizeButton/NormalButton';

import TopicModal from './components/topicModal';
import TopicContentBar from './components/topicContentBar';
import CheckDeleteModal from './components/checkDeleteModal';

import styles from './styles.module.scss';


@observer
class EDMEditDrawer extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
    this.props.viewModel.didMount();
  }

  render() {
    const { viewModel, width } = this.props;
    return (
      <>
        <Drawer
          placement="right"
          onClose={viewModel.onClose}
          open={viewModel.isOpen}
          width={Math.min(1080, width)}
          closeIcon={null}
          className={styles.drawer}
          styles={{ body: { padding: 0, display: 'grid', gridTemplateRows: '1fr auto' } }}
        >
          <div className={styles.contentContainer}>
            <div className={styles.titleRow}>
              <img src={CubeIcon} alt="" />
              <div className={styles.titleText}>
                {i18n.t('edm_drawer_title')}
              </div>
            </div>
            <section>
              <div className={styles.sectionTitle}>
                <img src={ArrowIcon} alt="" />
                <div className={styles.titleText}>
                  {i18n.t('edm_drawer_title')}
                </div>
              </div>
              <div className={styles.block}>
                <div className={styles.row}>
                  <div className={clsx(styles.rowTitle, (viewModel.isOnCheck && (!viewModel.valid.nameEmpty || !viewModel.valid.nameDuplicate)) && styles.error)}>
                    {i18n.t('edm_drawer_name_title')}
                  </div>
                  <div className={styles.rowContent}>
                    <Input
                      className={clsx(styles.input, styles.full, (viewModel.isOnCheck && (!viewModel.valid.nameEmpty || !viewModel.valid.nameDuplicate)) && styles.error)}
                      placeholder={i18n.t('edm_drawer_name_placeholder')}
                      value={viewModel.name}
                      onChange={viewModel.onNameChange}
                      disabled={viewModel.readOnly}
                      maxLength={30}
                      showCount
                    />
                    {
                      (viewModel.isOnCheck && !viewModel.valid.nameEmpty)
                      && (
                        <div className={styles.errorText}>
                          {i18n.t('edm_drawer_name_empty_error')}
                        </div>
                      )
                    }
                    {
                      (viewModel.isOnCheck && !viewModel.valid.nameDuplicate)
                      && (
                        <div className={styles.errorText}>
                          {i18n.t('edm_drawer_name_duplicate_error')}
                        </div>
                      )
                    }
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={clsx(styles.rowTitle, (viewModel.isOnCheck && !viewModel.valid.projectId) && styles.error)}>
                    {i18n.t('edm_drawer_project_title')}
                  </div>
                  <div className={styles.rowContent}>
                    <Select
                      className={clsx(styles.select)}
                      placeholder={i18n.t('edm_drawer_department_placeholder')}
                      suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                      showSearch
                      options={viewModel.departmentList}
                      value={viewModel.selectedDepartment}
                      onChange={viewModel.onDepartmentSelect}
                      filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                      disabled={viewModel.id || viewModel.readOnly}
                    />
                    <Select
                      className={clsx(styles.select)}
                      placeholder={i18n.t('edm_drawer_sales_placeholder')}
                      suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                      showSearch
                      options={viewModel.memberList}
                      value={viewModel.selectedMember}
                      onChange={viewModel.onMemberChange}
                      allowClear
                      filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                      disabled={viewModel.id || viewModel.readOnly}
                    />
                  </div>
                </div>
                <div className={styles.row}>
                  <div />
                  <div className={styles.rowContent}>
                    <Select
                      className={clsx(styles.select, styles.full, (viewModel.isOnCheck && !viewModel.valid.projectId) && styles.error)}
                      placeholder={i18n.t('edm_drawer_project_placeholder')}
                      suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                      showSearch
                      options={viewModel.formattedProjectsList}
                      value={viewModel.selectedProject}
                      onChange={viewModel.onProjectChange}
                      allowClear
                      filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                      disabled={viewModel.id || viewModel.readOnly}
                    />
                    {
                      (viewModel.isOnCheck && !viewModel.valid.projectId)
                      && (
                        <div className={styles.errorText}>
                          {i18n.t('edm_drawer_project_error')}
                        </div>
                      )
                    }
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={clsx(styles.rowTitle, (viewModel.isOnCheck && !viewModel.valid.date) && styles.error)}>
                    <div>{i18n.t('edm_drawer_time_title')}</div>
                    {
                      viewModel.id
                      && (
                        <div className={styles.changeNotice}>{i18n.t('edm_drawer_change_notice')}</div>
                      )
                    }
                  </div>
                  <div className={styles.rowContent}>
                    <DatePicker.RangePicker
                      className={clsx(styles.datePicker, styles.full, !viewModel.selectedProject && styles.disabled, (viewModel.isOnCheck && !viewModel.valid.date) && styles.error)}
                      value={viewModel.selectedDate}
                      placeholder={[i18n.t('edm_drawer_time_start_placeholder'), i18n.t('edm_drawer_time_end_placeholder')]}
                      changeOnBlur
                      allowClear
                      onCalendarChange={viewModel.onDateChange}
                      disabled={!viewModel.selectedProject || viewModel.readOnly}
                      disabledDate={(current) => {
                        return dayjs(current).tz('Asia/Taipei') < dayjs(viewModel.availableDate[0]).tz('Asia/Taipei').startOf('day')
                          || dayjs(current).tz('Asia/Taipei') > dayjs(viewModel.availableDate[1]).tz('Asia/Taipei').endOf('day');
                      }}
                    />
                    {
                      (viewModel.isOnCheck && !viewModel.valid.date)
                      && (
                        <div className={styles.errorText}>
                          {i18n.t('edm_drawer_time_error')}
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
              <div className={styles.block}>
                <div className={styles.row}>
                  <div className={clsx(styles.rowTitle, (viewModel.isOnCheck && !viewModel.valid.template) && styles.error)}>
                    {i18n.t('edm_drawer_template_title')}
                  </div>
                  <div className={styles.rowContent}>
                    <Select
                      className={clsx(styles.select, styles.full, !viewModel.selectedProject && styles.disabled, (viewModel.isOnCheck && !viewModel.valid.template) && styles.error)}
                      placeholder={i18n.t('edm_drawer_template_placeholder')}
                      suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                      options={EDM_TEMPLATE_OPTIONS}
                      value={viewModel.selectedTemplate}
                      onChange={viewModel.onTemplateSelect}
                      disabled={!viewModel.selectedProject || viewModel.readOnly}
                    />
                    {
                      (viewModel.isOnCheck && !viewModel.valid.template)
                      && (
                        <div className={styles.errorText}>
                          {i18n.t('edm_drawer_template_error')}
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
              <div className={styles.block}>
                <div className={styles.row}>
                  <div className={clsx(styles.rowTitle, (viewModel.isOnCheck && !viewModel.valid.template) && styles.error)}>
                    {i18n.t('edm_drawer_ai_summary_title')}
                  </div>
                  <div className={styles.rowContent}>
                    <Switch
                      className={clsx(styles.switch)}
                      disabled={viewModel.readOnly}
                      checked={viewModel.autoSummary}
                      onChange={viewModel.onAutoSummaryChange}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.block}>
                <div className={styles.row}>
                  <div className={clsx(styles.rowTitle, (viewModel.isOnCheck && !viewModel.valid.mainLevel1) && styles.error)}>
                    {
                      viewModel.projectSearchAngle === 'brand'
                        ? i18n.t('edm_drawer_main_brand_title')
                        : i18n.t('edm_drawer_main_brand_category_title')
                    }
                  </div>
                  <div className={styles.rowContent}>
                    <Select
                      className={clsx(styles.select, styles.full, !viewModel.selectedProject && styles.disabled, (viewModel.isOnCheck && !viewModel.valid.mainLevel1) && styles.error)}
                      placeholder={viewModel.projectSearchAngle === 'brand' ? i18n.t('edm_drawer_main_brand_placeholder') : i18n.t('edm_drawer_main_brand_category_placeholder')}
                      suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                      showSearch
                      options={viewModel.brandList}
                      value={viewModel.selectedBrand}
                      onChange={viewModel.onMainBrandSelect}
                      disabled={!viewModel.selectedProject || viewModel.readOnly}
                      filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                    />
                    {
                      (viewModel.isOnCheck && !viewModel.valid.mainLevel1)
                      && (
                        <div className={styles.errorText}>
                          {
                            viewModel.projectSearchAngle === 'brand'
                              ? i18n.t('edm_drawer_main_brand_error')
                              : i18n.t('edm_drawer_main_brand_category_error')
                          }
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
              <div className={styles.block}>
                <div className={styles.row}>
                  <div className={clsx(styles.rowTitle, (viewModel.isOnCheck && !viewModel.valid.feature) && styles.error)}>
                    <div>{i18n.t('edm_drawer_feature_title')}</div>
                    {
                      viewModel.id
                      && (
                        <div className={styles.changeNotice}>{i18n.t('edm_drawer_change_notice')}</div>
                      )
                    }
                  </div>
                  <div className={styles.rowContent}>
                    <Select
                      className={clsx(styles.select, styles.full, !viewModel.selectedProject && styles.disabled, (viewModel.isOnCheck && !viewModel.valid.feature) && styles.error)}
                      placeholder={i18n.t('edm_drawer_feature_placeholder')}
                      suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                      showSearch
                      options={viewModel.featureSetList}
                      value={viewModel.selectedFeatureSet}
                      onChange={viewModel.onFeatureSetSelect}
                      disabled={!viewModel.selectedProject || viewModel.readOnly}
                      filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                    />
                    {
                      (viewModel.isOnCheck && !viewModel.valid.feature)
                      && (
                        <div className={styles.errorText}>
                          {i18n.t('edm_drawer_feature_error')}
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
              <div className={styles.block}>
                <div className={styles.row}>
                  <div className={clsx(styles.rowTitle, (viewModel.isOnCheck && !viewModel.valid.topics) && styles.error)}>
                    <div>{i18n.t('edm_drawer_topic_setting_title')}</div>
                    {
                      viewModel.id
                      && (
                        <div className={styles.changeNotice}>{i18n.t('edm_drawer_change_notice')}</div>
                      )
                    }
                  </div>
                  <div className={styles.rowContent}>
                    <div className={styles.desc}>
                      <ExclamationCircleOutlined />
                      <div className={styles.descText}>
                        {i18n.t('edm_drawer_topic_setting_desc')}
                      </div>
                    </div>
                    {
                      (viewModel.isOnCheck && !viewModel.valid.topics)
                      && (
                        <div className={styles.errorText}>
                          {i18n.t('edm_drawer_topic_setting_error')}
                        </div>
                      )
                    }
                  </div>
                </div>
                {
                  viewModel.showTopics.map((topic, i) => {
                    return (
                      <div
                        className={styles.row}
                        key={topic.id}
                      >
                        <div className={clsx(styles.columnTitle)}>
                          <div>
                            {`${i18n.t('edm_drawer_topic_prefix')}${NUMBER_TC[i]}`}
                          </div>
                          <div>
                            {
                              topic.hasEvaluation
                                ? i18n.t('edm_drawer_topic_has_evaluate')
                                : i18n.t('edm_drawer_topic_no_evaluate')
                            }
                          </div>
                        </div>
                        <TopicContentBar viewModel={topic} />
                      </div>
                    );
                  })
                }
                <div className={styles.buttonContainer}>
                  <NormalButton
                    icon={<PlusOutlined />}
                    style={{ width: 160, height: 'unset', padding: '14px 15px' }}
                    onClick={viewModel.onAddNewTopic}
                    disabled={!viewModel.selectedProject || viewModel.showTopics.length >= 10 || viewModel.readOnly}
                  >
                    {i18n.t('edm_drawer_topic_prefix')}
                  </NormalButton>
                </div>
              </div>
            </section>
            <div className={clsx(styles.titleRow, styles.top20)}>
              <img src={CubeIcon} alt="" />
              <div className={styles.titleText}>
                {i18n.t('edm_drawer_manage_section_title')}
              </div>
            </div>
            <section>
              <div className={styles.sectionTitle}>
                <img src={ArrowIcon} alt="" />
                <div className={styles.titleText}>
                  {i18n.t('edm_drawer_editor_auth_title')}
                </div>
              </div>
              <div className={styles.block}>
                <div className={styles.row}>
                  <div className={styles.rowTitle}>
                    {i18n.t('edm_drawer_editor_title')}
                  </div>
                  <div className={styles.rowContent}>
                    <Select
                      className={clsx(styles.select, styles.full)}
                      placeholder={i18n.t('edm_drawer_editor_placeholder')}
                      mode="multiple"
                      suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                      options={viewModel.collaboratorList}
                      value={viewModel.selectedCollaborators}
                      onChange={viewModel.onCollaboratorSelect}
                      filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                      disabled={viewModel.readOnly}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className={clsx(styles.sectionTitle, styles.top20)}>
                <img src={ArrowIcon} alt="" />
                <div className={styles.titleText}>
                  {i18n.t('edm_drawer_setting_topic_title')}
                </div>
                <Tooltip
                  title={i18n.t('edm_drawer_setting_topic_desc')}
                  placement="bottomLeft"
                  overlayStyle={{ maxWidth: 'unset', whiteSpace: 'nowrap' }}
                >
                  <ExclamationCircleOutlined />
                </Tooltip>
              </div>
              <div className={styles.block}>
                <div className={styles.row}>
                  <div className={styles.rowTitle}>
                    {i18n.t('edm_drawer_setting_topic_sentiment_title')}
                  </div>
                  <div className={styles.rowContent}>
                    <div className={styles.tagContainer}>
                      {
                        viewModel.sentimentTags.map((tag) => {
                          return (
                            <AttributeTag
                              key={tag.name}
                              viewModel={tag}
                              closable={viewModel.sentimentTags.length > 1 && !viewModel.readOnly}
                            />
                          );
                        })
                      }
                      {
                        !viewModel.readOnly
                        && (
                          <PlusTag onCreateEnd={viewModel.onAddTag(TOPIC_ATTRIBUTE_TYPE.Rating)} />
                        )
                      }
                    </div>
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.rowTitle}>
                    {i18n.t('edm_drawer_setting_topic_attribute_title')}
                  </div>
                  <div className={styles.rowContent}>
                    <div className={styles.tagContainer}>
                      {
                        viewModel.attributeTags.map((tag) => {
                          return (
                            <AttributeTag
                              key={tag.name}
                              viewModel={tag}
                              closable={viewModel.attributeTags.length > 1 && !viewModel.readOnly}
                            />
                          );
                        })
                      }
                      {
                        !viewModel.readOnly
                        && (
                          <PlusTag onCreateEnd={viewModel.onAddTag(TOPIC_ATTRIBUTE_TYPE.Topic)} />
                        )
                      }
                    </div>
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.rowTitle}>
                    {i18n.t('edm_drawer_setting_topic_reply_title')}
                  </div>
                  <div className={styles.rowContent}>
                    <div className={styles.tagContainer}>
                      {
                        viewModel.replyTags.map((tag) => {
                          return (
                            <AttributeTag
                              key={tag.name}
                              viewModel={tag}
                              closable={viewModel.replyTags.length > 1 && !viewModel.readOnly}
                            />
                          );
                        })
                      }
                      {
                        !viewModel.readOnly
                        && (
                          <PlusTag onCreateEnd={viewModel.onAddTag(TOPIC_ATTRIBUTE_TYPE.Suggestion)} />
                        )
                      }
                    </div>
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.rowTitle}>
                    {i18n.t('edm_drawer_setting_topic_department_title')}
                  </div>
                  <div className={styles.rowContent}>
                    <div className={styles.tagContainer}>
                      {
                        viewModel.departmentTags.map((tag) => {
                          return (
                            <AttributeTag
                              key={tag.name}
                              viewModel={tag}
                              closable={viewModel.departmentTags.length > 1 && !viewModel.readOnly}
                            />
                          );
                        })
                      }
                      {
                        !viewModel.readOnly
                        && (
                          <PlusTag onCreateEnd={viewModel.onAddTag(TOPIC_ATTRIBUTE_TYPE.Department)} />
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className={clsx(styles.titleRow, styles.top20)}>
              <img src={CubeIcon} alt="" />
              <div className={styles.titleText}>
                {i18n.t('edm_drawer_setting_receiver_setting_section_title')}
              </div>
            </div>
            <section>
              <div className={styles.block}>
                <div className={styles.row}>
                  <div className={clsx(styles.rowTitle, (viewModel.isOnCheck && !viewModel.valid.subject) && styles.error)}>
                    {i18n.t('edm_drawer_setting_mail_title')}
                  </div>
                  <div className={styles.rowContent}>
                    <Input
                      className={clsx(styles.input, styles.full, (viewModel.isOnCheck && !viewModel.valid.subject) && styles.error)}
                      placeholder={i18n.t('edm_drawer_setting_mail_title_placeholder')}
                      maxLength={60}
                      value={viewModel.subject}
                      onChange={viewModel.onSubjectChange}
                      disabled={viewModel.readOnly}
                      showCount
                    />
                    {
                      (viewModel.isOnCheck && !viewModel.valid.subject)
                      && (
                        <div className={styles.errorText}>
                          {i18n.t('edm_drawer_setting_mail_error')}
                        </div>
                      )
                    }
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.rowTitle}>
                    {i18n.t('edm_drawer_setting_receiver_setting_title')}
                  </div>
                  <div className={clsx(styles.rowContent, styles.autoContent)}>
                    <Input
                      className={clsx(styles.input)}
                      placeholder={i18n.t('edm_drawer_setting_receiver_setting_desc')}
                      value={viewModel.receiverInput}
                      onChange={viewModel.onReceiverChange}
                      onKeyDown={viewModel.onReceiverKeydown}
                      disabled={viewModel.readOnly}
                    />
                    <NormalButton
                      icon={<PlusOutlined />}
                      style={{ height: 44, minWidth: 'unset' }}
                      onClick={viewModel.addReceiver}
                      disabled={viewModel.readOnly}
                    >
                      {i18n.t('common_create')}
                    </NormalButton>
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={clsx(styles.rowTitle, (viewModel.isOnCheck && !viewModel.valid.receivers) && styles.error)}>
                    {i18n.t('edm_drawer_setting_receiver_list_title')}
                  </div>
                  <div className={clsx(styles.rowContent)}>
                    <div className={clsx(styles.dummyContainer, (viewModel.isOnCheck && !viewModel.valid.receivers) && styles.error)}>
                      <div className={clsx(styles.tagContainer)}>
                        {
                          viewModel.receiver.length === 0
                          && (
                            <div className={clsx(styles.emptyNote, (viewModel.isOnCheck && !viewModel.valid.receivers) && styles.error)}>
                              {i18n.t('edm_drawer_setting_receiver_list_placeholder')}
                            </div>
                          )
                        }
                        {
                          viewModel.receiver.map((receiver) => {
                            return (
                              <Tag
                                className={clsx(styles.tagStyle)}
                                key={receiver}
                                closable={!viewModel.readOnly}
                                onClose={() => viewModel.onReceiverDeleteClick(receiver)}
                              >
                                {receiver}
                              </Tag>
                            );
                          })
                        }
                      </div>
                    </div>
                    {
                      (viewModel.isOnCheck && !viewModel.valid.receivers)
                      && (
                        <div className={styles.errorText}>
                          {i18n.t('edm_drawer_setting_receiver_nodata_error')}
                        </div>
                      )
                    }
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={clsx(styles.rowTitle, (viewModel.isOnCheck && !viewModel.valid.frequency) && styles.error)}>
                    <div>
                      {i18n.t('edm_drawer_setting_frequency_title')}
                    </div>
                    <Tooltip
                      title={i18n.t('edm_drawer_setting_frequency_desc')}
                      placement="bottomLeft"
                      overlayStyle={{ maxWidth: 'unset', whiteSpace: 'pre-wrap' }}
                    >
                      <ExclamationCircleOutlined />
                    </Tooltip>
                  </div>
                  <div className={styles.rowContent}>
                    <Select
                      className={clsx(styles.select, styles.full, (viewModel.isOnCheck && !viewModel.valid.frequency) && styles.error)}
                      placeholder={i18n.t('edm_drawer_setting_frequency_placeholder')}
                      options={SEND_FREQUENCY_OPTIONS}
                      value={viewModel.selectedFrequency}
                      onChange={viewModel.onFrequencyChange}
                      mode="multiple"
                      suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                      filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                      disabled={viewModel.readOnly}
                    />
                    {
                      (viewModel.isOnCheck && !viewModel.valid.frequency)
                      && (
                        <div className={styles.errorText}>
                          {i18n.t('edm_drawer_setting_frequency_error')}
                        </div>
                      )
                    }
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.rowTitle}>
                    {i18n.t('edm_drawer_setting_auto_send_title')}
                  </div>
                  <div className={styles.autoSendRow}>
                    <Switch
                      className={clsx(styles.switch)}
                      checked={viewModel.autoSend}
                      onChange={viewModel.onAutoSendChange}
                      disabled={viewModel.readOnly}
                    />
                    {
                      viewModel.autoSend && viewModel.selectedFrequency.includes('multiple')
                      && (
                        <div className={styles.toadyOptions}>
                          <div>
                            {i18n.t('edm_drawer_setting_auto_send_today_title')}
                          </div>
                          <div className={styles.option}>
                            <Checkbox
                              checked={viewModel.dailyNoon}
                              onChange={viewModel.onDailyNoonChange}
                              disabled={viewModel.readOnly}
                            />
                            {i18n.t('edm_drawer_setting_auto_send_today_noon')}
                          </div>
                          <div className={styles.option}>
                            <Checkbox
                              checked={viewModel.dailyNight}
                              onChange={viewModel.onDailyNightChange}
                              disabled={viewModel.readOnly}
                            />
                            {i18n.t('edm_drawer_setting_auto_send_today_night')}
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className={styles.drawerFooter}>
            <Tooltip
              title={i18n.t('edm_setting_drawer_submit_button_tooltip')}
              placement="top"
              overlayStyle={{ maxWidth: 'unset', whiteSpace: 'pre-wrap' }}
            >
              {
                viewModel.readOnly
                  ? (
                    <FilledButton
                      onClick={viewModel.onSubmit}
                      disabled={!viewModel.canSubmit}
                    >
                      {i18n.t('common_apply')}
                    </FilledButton>
                  )
                  : (
                    <FilledButton
                      onClick={viewModel.onSubmit}
                      disabled={!viewModel.canSubmit}
                    >
                      {i18n.t('common_apply')}
                    </FilledButton>
                  )
              }
            </Tooltip>
          </div>
          <CloseOutlined
            className={styles.closeBtn}
            onClick={viewModel.onClose}
          />
        </Drawer>
        <TopicModal viewModel={viewModel.topicModalViewModel} />
        <CheckDeleteModal viewModel={viewModel.checkDeleteModalViewModel} />
      </>
    );
  }
}

EDMEditDrawer.propTypes = {
  viewModel: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired
};

EDMEditDrawer.defaultProps = {
  // viewModel: {}
};

export default EDMEditDrawer;
